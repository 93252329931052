import React, { useEffect, useRef, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    Radio,
    RadioGroup,
    FormControlLabel,
    Typography,
    Box,
    Slider,
} from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const CustomToolbar = () => (
    <div id="toolbar">
        <span className="ql-formats">
            <button className="ql-bold" title="Bold"></button>
            <button className="ql-italic" title="Italic"></button>
            <button className="ql-underline" title="Underline"></button>
            <button className="ql-strike" title="Strikethrough"></button>
        </span>
        <span className="ql-formats">
            <button className="ql-list" value="ordered" title="Ordered List"></button>
            <button className="ql-list" value="bullet" title="Bullet List"></button>
        </span>
        <span className="ql-formats">
            <select className="ql-color" title="Text Color"></select>
            <select className="ql-background" title="Background Color"></select>
        </span>
        <span className="ql-formats">
            <select class="ql-align"></select>
        </span>
        <span className="ql-formats">
            <button className="ql-clean" title="Remove Formatting"></button>
        </span>
    </div>
);

const TextEditorDialog = ({ open, onClose, onAddText, initialTextData = {}, handleSaveText, isEditMode = false }) => {
    const [text, setText] = useState('');
    const [textSize, setTextSize] = useState(26);
    const [textColor, setTextColor] = useState('#000000');
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [alignment, setAlignment] = useState('left');
    const [charCount, setCharCount] = useState(0);
    const maxChars = 500;        
    
    
    useEffect(() => {
        if (isEditMode && initialTextData) {            
            setText(initialTextData.text || '');
            setTextSize(initialTextData.size || 26);
            setTextColor(initialTextData.textColor || '#000000');
            setIsBold(initialTextData.isBold || false);
            setIsItalic(initialTextData.isItalic || false);
            setAlignment(initialTextData.alignment || 'left');
            setCharCount((initialTextData.text || '').replace(/<\/?[^>]+(>|$)/g, "").length);
        } 
    }, [open, isEditMode, initialTextData]);
   

    const handleTextChange = (value) => {        
        const plainText = value.replace(/<\/?[^>]+(>|$)/g, "");        
        setCharCount(plainText.length); 
        setText(value); 
        // if (plainText.length <= maxChars) {
                       
        // }
    };

    const handleTextSizeChange = (event) => {
        console.log(event, "size");
        
        setTextSize(Number(event.target.value));

    };

    // const handleAddText = () => {        
    //     onAddText({ text, textSize, textColor, isBold, isItalic, alignment });
    //     setText('');
    //     setTextColor('#000000');
    //     setTextSize(18);
    //     setIsBold(false);
    //     setIsItalic(false);
    //     setAlignment('left');
    //     onClose();
    // };


    const handleSave = () => {
        const textData = { text, textSize, textColor, isBold, isItalic, alignment };
        const updatedTextObject = {
            id: initialTextData.id, 
            text,        
            size: textSize,    
            textColor,   
            isBold,      
            isItalic,
            alignment,
        };
        console.log(updatedTextObject, "datta");
        
        if (isEditMode) {
            handleSaveText(updatedTextObject);
            setTextSize(null)
        } else {
            onAddText(textData);
            setTextSize(null)
        }
        handleCloseDialog();
    };

    const handleCloseDialog = () => {
        onClose();
        resetFields()
    };

    const resetFields = () => {
        setText('');
        setTextSize(26);
        setTextColor('#000000');
        setIsBold(false);
        setIsItalic(false);
        setAlignment('left');
        setCharCount(0);
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            sx={{
                '& .MuiDialog-paper': {
                    minHeight: '400px',
                    maxHeight: '90vh',
                },
            }}
        >
            <DialogTitle
                sx={{
                    backgroundColor: 'white',
                }}>Text</DialogTitle>
            <DialogContent
                sx={{
                    backgroundColor: 'white',
                }}>
                <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
                    Text size
                </Typography>
                {/* <RadioGroup row value={textSize} onChange={handleTextSizeChange}>
                    <FormControlLabel
                        value={30}
                        control={<Radio />}
                        label="Heading 1 (30px)"
                    />
                    <FormControlLabel
                        value={28}
                        control={<Radio />}
                        label="Heading 2 (28px)"
                    />
                    <FormControlLabel
                        value={18}
                        control={<Radio />}
                        label="Paragraph (18px)"
                    />
                </RadioGroup> */}
                <Slider
                    value={textSize}
                    min={18}
                    max={62}
                    onChange={handleTextSizeChange}
                    valueLabelDisplay="auto"
                    aria-labelledby="text-size-slider"
                />

                <Typography variant="subtitle1">Page text</Typography>
                <Box>
                    <CustomToolbar/>
                    <ReactQuill                        
                        value={text}
                        onChange={handleTextChange}
                        theme="snow"
                        modules={{
                            toolbar: {
                                container: "#toolbar"
                            },
                            
                        }}
                        formats={['bold', 'italic', 'underline', 'strike', 'header', 'list', 'color', 'background', 'align']}
                        style={{
                            height: '200px',
                            marginBottom: '20px',
                            fontSize: `${textSize}px`,
                            color: textColor,                            
                            fontWeight: isBold ? 'bold' : 'normal',
                            fontStyle: isItalic ? 'italic' : 'normal',                                                                                
                        }}
                    />
                    <div style={{ marginTop: '10px', fontSize: '14px', color: '#555' }}>
                        Character count: {charCount}/{maxChars}
                    </div>
                    {charCount >= maxChars && (
                        <div style={{ color: 'red', fontSize: '12px' }}>
                            You have reached the maximum character limit of {maxChars} characters.
                        </div>
                    )}
                </Box>

                <Box textAlign="center" sx={{ marginTop: 8 }}>
                    <Button variant="contained" color="primary" onClick={handleSave} disabled={!text.trim() || charCount > maxChars}>
                        {isEditMode ? 'Save Changes' : 'Add Text'}
                    </Button>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: '#FF0004',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#D00000',
                            },
                        }}
                        onClick={handleCloseDialog}
                    >
                        Close
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default TextEditorDialog;
