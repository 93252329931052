import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  Box,
  TableSortLabel,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import axiosInstance from "../utils/AxiosInstance";

const BookTable = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedBook, setSelectedBook] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [books, setBooks] = useState([]);
  const [selectedBookId, setSelectedBookId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [originalBooks, setOriginalBooks] = useState([]);

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");

  //console.log("lll",selectedBook);

  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
    if (searchValue === "") {
      setBooks(originalBooks); // Reset to original list if search input is cleared
    } else {
      const filteredBooks = books.filter((book) => {
        const title = book.title?.toLowerCase() || "";
        const authorName = book.author_name?.toLowerCase() || "";
        const categoryName = book.category_name?.toLowerCase() || "";
  
        return (
          title.includes(searchValue) ||
          authorName.includes(searchValue) ||
          categoryName.includes(searchValue)
        );
      });
      setBooks(filteredBooks);
      setPage(0);
    }
  };
  

  const fetchAllBooks = async () => {
    try {
      const response = await axiosInstance.get("/admin/all-books");
      // Reverse the order of the books so the most recently added appear first
      const reversedBooks = response.data.reverse();
      //console.log("dfdddddddd",reversedBooks);
      
      setBooks(reversedBooks);
      setOriginalBooks(reversedBooks);
    } catch (error) {
      if (error.response.status === 401) {
        window.location.href = '/auth/login';
      }      
      //console.error("Error fetching published books:", error);
    }
  };
  

  useEffect(() => {
    fetchAllBooks();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleViewBook = (book) => {
    setSelectedBook(book);
    setDialogOpen(true);
  };

  const handleDeleteBook = async () => {
    //console.log("--00--",selectedBookId)
    if (!selectedBookId) {
      //console.error("No book ID selected for deletion");
      return;
    }

    try {
      //console.log("---selectedBookId----", selectedBookId);
      await axiosInstance.put(`/books/${selectedBookId}/status`, {
        approval_status: "deleted",
      });

      const updatedBooks = books.map((b) =>
        b.id === selectedBookId ? { ...b, approval_status: "deleted" } : b
      );

      setBooks(updatedBooks);
      fetchAllBooks();
      setDeleteDialogOpen(false);
      setDialogOpen(false);
    } catch (error) {
      if (error.response.status === 401) {
        window.location.href = '/auth/login';
      }      
      //console.error("Error updating book status:", error);
    }
  };

  const handleOpenDeleteDialog = () => {
    setSelectedBookId(selectedBook.book_id);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, books.length - page * rowsPerPage);

  const getStatusColor = (status) => {
    switch (status) {
      case "rejected":
        return "red";
      case "rejected by admin":
        return "red";
      case "deleted":
        return "#e17400";
      case "approved":
        return "#d8d800";

      case "published":
        return "#034103";
      case "submitted":
        return "#05e605";
      case "waiting for approval":
        return "#d8d800";
      default:
        return "black";
    }
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedBooks = books.sort((a, b) => {

    if (orderBy) {
      if (order === "asc") {
        return a[orderBy]?.toLowerCase() > b[orderBy]?.toLowerCase() ? 1 : -1;
      } else {
        return a[orderBy]?.toLowerCase() < b[orderBy]?.toLowerCase() ? 1 : -1;
      }
    }
    return 0;
  });

  
  const handleDialogOpen = (book) => {
    setSelectedBook(book);
    setOpenDialog(true);
  };
  return (
    <>
      <Box>
        <Box
          sx={{ marginBottom: "20px", display: "flex", alignItems: "center" }}
        >
          <Typography variant="h4" sx={{ flexGrow: 1 }}>
            Books
          </Typography>
          <TextField
            label="Search Books"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearch}
          />
        </Box>
        <Paper>
        <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#1976d2" }}>
                  <TableCell sx={{ color: "#fff" }}>
                    S.No
                    </TableCell>
                  {/* Sorting added to Book Name */}
                  <TableCell sx={{ color: "#fff" }}>
                    <TableSortLabel
                      active={orderBy === "title"}
                      direction={orderBy === "title" ? order : "asc"}
                      onClick={() => handleSort("title")}
                      hideSortIcon={false}
                      sx={{
                        color: orderBy === "title" ? "#000" : "#fff",
                        "& .MuiTableSortLabel-icon": {
                          opacity: 1,                          
                        },           
                        "&.MuiTableSortLabel-root": {
                          color: "#fff",
                        },
                        "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                          color: "#000", 
                        },
                        "&:hover": {
                          color: "#f0f0f0", 
                          "& .MuiTableSortLabel-icon": {
                            color: "#f0f0f0", 
                          },
                        }           
                      }}
                    >
                      Book Name
                    </TableSortLabel>
                  </TableCell>
                  {/* Sorting added to Author Name */}
                  <TableCell sx={{ color: "#fff" }}>
                    <TableSortLabel
                      active={orderBy === "author_name"}
                      direction={orderBy === "author_name" ? order : "asc"}
                      onClick={() => handleSort("author_name")}
                      hideSortIcon={false}
                      sx={{
                        color: orderBy === "author_name" ? "#000" : "#fff",
                        "& .MuiTableSortLabel-icon": {
                          opacity: 1,                          
                        },           
                        "&.MuiTableSortLabel-root": {
                          color: "#fff",
                        },
                        "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                          color: "#000", 
                        },
                        "&:hover": {
                          color: "#f0f0f0", 
                          "& .MuiTableSortLabel-icon": {
                            color: "#f0f0f0", 
                          },
                        }           
                      }}
                    >
                      Author Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    <TableSortLabel
                        active={orderBy === "school_name"}
                        direction={orderBy === "school_name" ? order : "asc"}
                        onClick={() => handleSort("school_name")}
                        hideSortIcon={false}
                        sx={{
                          color: orderBy === "school_name" ? "#000" : "#fff",
                          "& .MuiTableSortLabel-icon": {
                            opacity: 1,                          
                          },           
                          "&.MuiTableSortLabel-root": {
                            color: "#fff",
                          },
                          "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                            color: "#000", 
                          },
                          "&:hover": {
                            color: "#f0f0f0", 
                            "& .MuiTableSortLabel-icon": {
                              color: "#f0f0f0", 
                            },
                          }           
                        }}
                    >
                    School Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    <TableSortLabel
                        active={orderBy === "grade"}
                        direction={orderBy === "grade" ? order : "asc"}
                        onClick={() => handleSort("grade")}
                        hideSortIcon={false}
                        sx={{
                          color: orderBy === "grade" ? "#000" : "#fff",
                          "& .MuiTableSortLabel-icon": {
                            opacity: 1,                          
                          },           
                          "&.MuiTableSortLabel-root": {
                            color: "#fff",
                          },
                          "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                            color: "#000", 
                          },
                          "&:hover": {
                            color: "#f0f0f0", 
                            "& .MuiTableSortLabel-icon": {
                              color: "#f0f0f0", 
                            },
                          }           
                        }}
                    >
                    Grade
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                  <TableSortLabel
                      active={orderBy === "level_name"}
                      direction={orderBy === "level_name" ? order : "asc"}
                      onClick={() => handleSort("level_name")}
                      hideSortIcon={false}
                      sx={{
                        color: orderBy === "level_name" ? "#000" : "#fff",
                        "& .MuiTableSortLabel-icon": {
                          opacity: 1,                          
                        },           
                        "&.MuiTableSortLabel-root": {
                          color: "#fff",
                        },
                        "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                          color: "#000", 
                        },
                        "&:hover": {
                          color: "#f0f0f0", 
                          "& .MuiTableSortLabel-icon": {
                            color: "#f0f0f0", 
                          },
                        }           
                      }}
                  >
                    Level
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    <TableSortLabel
                        active={orderBy === "category_name"}
                        direction={orderBy === "category_name" ? order : "asc"}
                        onClick={() => handleSort("category_name")}
                        hideSortIcon={false}
                        sx={{
                          color: orderBy === "category_name" ? "#000" : "#fff",
                          "& .MuiTableSortLabel-icon": {
                            opacity: 1,                          
                          },           
                          "&.MuiTableSortLabel-root": {
                            color: "#fff",
                          },
                          "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                            color: "#000", 
                          },
                          "&:hover": {
                            color: "#f0f0f0", 
                            "& .MuiTableSortLabel-icon": {
                              color: "#f0f0f0", 
                            },
                          }           
                        }}
                    >
                    Category
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    <TableSortLabel
                        active={orderBy === "approval_status"}
                        direction={orderBy === "approval_status" ? order : "asc"}
                        onClick={() => handleSort("approval_status")}
                        hideSortIcon={false}
                        sx={{
                          color: orderBy === "approval_status" ? "#000" : "#fff",
                          "& .MuiTableSortLabel-icon": {
                            opacity: 1,                          
                          },           
                          "&.MuiTableSortLabel-root": {
                            color: "#fff",
                          },
                          "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                            color: "#000", 
                          },
                          "&:hover": {
                            color: "#f0f0f0", 
                            "& .MuiTableSortLabel-icon": {
                              color: "#f0f0f0", 
                            },
                          }             
                        }}
                    >
                    Status
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? sortedBooks.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : sortedBooks
                ).map((book, index) => (
                  <TableRow key={book.book_id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{book.title}</TableCell>
                    <TableCell>{book.author_name}</TableCell>
                    <TableCell>{book.school_name}</TableCell>
                    <TableCell>{book.grade}</TableCell>
                    <TableCell>{book.level_name}</TableCell>
                    <TableCell>{book.category_name}</TableCell>
                    <TableCell>
                      <Typography
                        onClick={
                          book.approval_status === "rejected"
                            ? () => handleDialogOpen(book)
                            : null
                        }
                        sx={{
                          color: getStatusColor(book.approval_status),
                          cursor:
                            book.approval_status === "rejected"
                              ? "pointer"
                              : "default",
                        }}
                      >
                        {book.approval_status}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleViewBook(book)}>
                        <VisibilityIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}

                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100, 500]}
            component="div"
            count={books.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          {selectedBook && (
            <Dialog
              open={dialogOpen}
              onClose={handleCloseDialog}
              maxWidth="sm"
              fullWidth
            >
              <DialogTitle>Book Details</DialogTitle>
              <DialogContent>
                <Grid container spacing={2} sx={{ paddingTop: "10px" }}>
                  <Grid item xs={6}>
                    <TextField
                      label="Book Name"
                      value={selectedBook.title}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Author Name"
                      value={selectedBook.author_name}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Level"
                      value={selectedBook.level_name}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Category"
                      value={selectedBook.category_name}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Status"
                      value={selectedBook.approval_status}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: "center" }}>
                    {selectedBook.approval_status !== "deleted" && (
                      <IconButton
                        onClick={() =>
                          handleOpenDeleteDialog(selectedBook.book_id)
                        }
                        sx={{ backgroundColor: "red", color: "white" }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          )}

          <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
            <DialogTitle>
              Are you sure you want to delete this book?
            </DialogTitle>
            <DialogActions>
              <Button onClick={handleCloseDeleteDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDeleteBook} color="error">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Box>
    </>
  );
};

export default BookTable;
