import React, { useState, useEffect } from "react";
import { green, red } from "@mui/material/colors";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Checkbox,
  Box,
  Snackbar,
  SnackbarContent,
  TableSortLabel,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axiosInstance from "../utils/AxiosInstance";
import DeleteIcon from "@mui/icons-material/Delete";




const CommentsControl = ({ messageType }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [comments, setComments] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedBooks, setSelectedBooks] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [selectedComments, setSelectedComments] = useState([]);
  const [books, setBooks] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedBook, setSelectedBook] = useState(null);
  const [originalcomments, setcomments] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");
  const [selectedComment, setSelectedComment] = useState(null);
  // const [selectedBooks, setSelectedBooks] = useState([]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(null);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const getComparator = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => (b[orderBy] < a[orderBy] ? -1 : 1)
      : (a, b) => (b[orderBy] > a[orderBy] ? -1 : 1);
  };

  const getSnackbarStyle = (type) => {
    switch (type) {
      case "success":
        return { backgroundColor: green[600] };
      case "delete":
        return { backgroundColor: red[600] };
      default:
        return {};
    }
  };
  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
    if (searchValue === "") {
      setComments(originalcomments);
    } else {
      const filteredComments = comments.filter(
        (comment) =>
          comment.book_name
            .toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          comment.user_name
            .toLowerCase()
            .includes(event.target.value.toLowerCase())
      );
      setComments(filteredComments);
      setPage(0);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleViewBook = (comments) => {
    setSelectedComments([comments.comment_text, comments.book_id]);
    setDialogContent(
      <>
        <Typography variant="h6">Comments for {comments.book_name}:</Typography>
        <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
          <li style={{ display: "flex", alignItems: "center" }}>
            {comments.comment_text}
          </li>
        </ul>
        {comments.audio_url && (
          <>
            <Typography variant="h6">Audio Comments:</Typography>
            <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
              <li style={{ display: "flex", alignItems: "center" }}>
                <audio
                  controls
                  src={`https://story-buzz.blr1.digitaloceanspaces.com/uploads/books/comments/audio/${comments.audio_url}`}
                ></audio>
              </li>
            </ul>
          </>
        )}
        {comments.video_url && (
          <>
            <Typography variant="h6">Video Comments:</Typography>
            <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
              <li style={{ display: "flex", alignItems: "center" }}>
                <video
                  controls
                  style={{ width: "450px", height: "350px" }}
                  src={`https://story-buzz.blr1.digitaloceanspaces.com/uploads/books/comments/video/${comments.video_url}`}
                ></video>
              </li>
            </ul>
          </>
        )}
      </>
    );
    setDialogOpen(true);
  };

  const handleSelectComment = (comments) => {
    const updatedSelectedComments = selectedComments.includes(comments)
      ? selectedComments.filter((c) => c !== comments)
      : [...selectedComments, comments];
    setSelectedComments(updatedSelectedComments);
  };

  const handlePublishSelected = () => {
    const updatedBooks = comments.filter(
      (comments) => !selectedComments.includes(comments)
    );
    setComments(updatedBooks);
    setSelectedBooks([]);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setDialogOpen(false)
  };
  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await axiosInstance.get(`/books/getAllComments`);
        setComments(response.data);
        setcomments(response.data);
      } catch (error) {
        if (error.response.status === 401) {
          window.location.href = '/auth/login';
        }      
        //console.error("Error fetching comments:", error);
      }
    };

    fetchComments();
  }, []);

  const handleApprove = async () => {
    //console.log("--handleApprove---", selectedComments);

    if (selectedComments.length > 0) {
      //console.log("--selectedComments---", selectedComments);
      try {
        // Structure the data to send in the request body
        const commentsWithBookId = selectedComments.map((comment) => ({
          text: comment.comment_text, // or the appropriate field for comment text
          bookId: comment.book_id, // or the appropriate field for book ID
        }));

        const response = await axiosInstance.post("/books/approveComments", {
          comments: commentsWithBookId,
        });
        //console.log("--response---", response);
        //console.log("Approved comments:", response.data);

        // Optionally, fetch the comments again to update the UI
        // fetchComments();
        handleCloseDialog();
      } catch (error) {
        //console.error("Error approving comments:", error);
      }
    }
  };

  const handlePublishAll = async () => {
    //console.log("----selectedComments--", selectedComments);
    const selectedCommentsIds = selectedComments.map((comment) => ({
      id: comment.id,
      user_id: comment.user_id,
      book_id: comment.book_id,
    }));
    //console.log("----selectedCommentsIds--", selectedCommentsIds);
    try {
      await axiosInstance.put("/books/commentsApproved", {
        comments: selectedCommentsIds,
      });

      const updatedComments = comments.filter(
        (comment) => !selectedCommentsIds.some((sc) => sc.id === comment.id)
      );

      setComments(updatedComments);
      setSelectedComments([]);
      setSnackbarMessage("Comment published successfully!");
      setSnackbarType("success");
      setSnackbarOpen(true);
    } catch (error) {
      if (error.response.status === 401) {
        window.location.href = '/auth/login';
      }      
      //console.error("Error publishing books:", error);
    }
  };

  const handleDelete = async (id, user_id, book_id) => {
    //console.log("-----id, user_id, book_id----", id, user_id, book_id);
    try {
      const response = await axiosInstance.post("/books/deleteComment", {
        id,
        user_id,
        book_id,
      });

      // const result = await response.json();
      //console.log("Delete Response:", result);

      if (response.status === 200) {
        const updatedComments = comments.filter((comment) => comment.id !== id);
        setComments(updatedComments);
        setSelectedComments(
          selectedComments.filter((comment) => comment.id !== id)
        );
        setSnackbarMessage("Comment deleted successfully!");
        setSnackbarType("delete");
        setSnackbarOpen(true);
      } else {
        // Handle failure, e.g., show an error message
      }
    } catch (error) {
      if (error.response.status === 401) {
        window.location.href = '/auth/login';
      }      
      //console.error("Error deleting comment:", error);
    }
  };

  const handleOpenDialog = (comment) => {
    setSelectedComment(comment);
    setOpenDialog(true);
    // setDialogOpen(true)
  };

  const confirmDelete = () => {
    if (selectedComment) {
      handleDelete(
        selectedComment.id,
        selectedComment.user_id,
        selectedComment.book_id
      );
      setOpenDialog(false); // Close the dialog after deletion
    }
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, comments.length - page * rowsPerPage);
  const getStatusColor = (status) => {
    switch (status) {
      case "rejected":
        return "red";
      case "rejected by admin":
        return "red";
      case "deleted":
        return "#e17400";
      case "approved":
        return "#d8d800";

      case "published":
        return "#034103";
      case "submitted":
        return "#05e605";
      case "waiting for approval":
        return "#d8d800";
      default:
        return "black";
    }
  };
  const handleDialogOpen = (comment) => {
    setSelectedBook(comment);
    setOpenDialog(true);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedComments = comments.sort(getComparator(order, orderBy));
  return (
    <>
      <Box>
        <Box sx={{ marginBottom: "20px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: "15px",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h4">Comments Control</Typography>
            <TextField
              label="Search Comments"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearch}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePublishAll}
              disabled={selectedComments.length === 0}
            >
              Publish All
            </Button>
          </Box>
        </Box>

        <Paper>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: "#1976d2" }}>
                  <TableCell sx={{ color: "#fff" }}>S.No</TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                      <TableSortLabel
                        active={orderBy === "book_name"}
                        direction={orderBy === "book_name" ? order : "asc"}
                        onClick={() => handleRequestSort("book_name")}
                        hideSortIcon={false}
                        sx={{
                          color: orderBy === "book_name" ? "#000" : "#fff",
                          "& .MuiTableSortLabel-icon": {
                            opacity: 1,                          
                          },           
                          "&.MuiTableSortLabel-root": {
                            color: "#fff",
                          },
                          "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                            color: "#000", 
                          },
                          "&:hover": {
                            color: "#f0f0f0", 
                            "& .MuiTableSortLabel-icon": {
                              color: "#f0f0f0", 
                            },
                          }           
                        }}
                      >
                      Book Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                      <TableSortLabel
                        active={orderBy === "author_name"}
                        direction={orderBy === "author_name" ? order : "asc"}
                        onClick={() => handleRequestSort("author_name")}
                        hideSortIcon={false}
                        sx={{
                          color: orderBy === "author_name" ? "#000" : "#fff",
                          "& .MuiTableSortLabel-icon": {
                            opacity: 1,                          
                          },           
                          "&.MuiTableSortLabel-root": {
                            color: "#fff",
                          },
                          "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                            color: "#000", 
                          },
                          "&:hover": {
                            color: "#f0f0f0", 
                            "& .MuiTableSortLabel-icon": {
                              color: "#f0f0f0", 
                            },
                          }           
                        }}
                      >
                      Author Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                      <TableSortLabel
                        active={orderBy === "user_name"}
                        direction={orderBy === "user_name" ? order : "asc"}
                        onClick={() => handleRequestSort("user_name")}
                        hideSortIcon={false}
                        sx={{
                          color: orderBy === "user_name" ? "#000" : "#fff",
                          "& .MuiTableSortLabel-icon": {
                            opacity: 1,                          
                          },           
                          "&.MuiTableSortLabel-root": {
                            color: "#fff",
                          },
                          "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                            color: "#000", 
                          },
                          "&:hover": {
                            color: "#f0f0f0", 
                            "& .MuiTableSortLabel-icon": {
                              color: "#f0f0f0", 
                            },
                          }           
                        }}
                      >
                    Commented By
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                      <TableSortLabel
                        active={orderBy === "comment.created_at"}
                        direction={orderBy === "comment.created_at" ? order : "asc"}
                        onClick={() => handleRequestSort("comment.created_at")}
                        hideSortIcon={false}
                        sx={{
                          color: orderBy === "comment.created_at" ? "#000" : "#fff",
                          "& .MuiTableSortLabel-icon": {
                            opacity: 1,                          
                          },           
                          "&.MuiTableSortLabel-root": {
                            color: "#fff",
                          },
                          "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                            color: "#000", 
                          },
                          "&:hover": {
                            color: "#f0f0f0", 
                            "& .MuiTableSortLabel-icon": {
                              color: "#f0f0f0", 
                            },
                          }           
                        }}
                      >
                      Commented On
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                  <TableSortLabel
                        active={orderBy === "comment.status"}
                        direction={orderBy === "comment.status" ? order : "asc"}
                        onClick={() => handleRequestSort("comment.status")}
                        hideSortIcon={false}
                        sx={{
                          color: orderBy === "comment.status" ? "#000" : "#fff",
                          "& .MuiTableSortLabel-icon": {
                            opacity: 1,                          
                          },           
                          "&.MuiTableSortLabel-root": {
                            color: "#fff",
                          },
                          "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                            color: "#000", 
                          },
                          "&:hover": {
                            color: "#f0f0f0", 
                            "& .MuiTableSortLabel-icon": {
                              color: "#f0f0f0", 
                            },
                          }           
                        }}
                      >
                        Status
                      </TableSortLabel>
                      </TableCell>
                  <TableCell sx={{ color: "#fff" }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? sortedComments.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                  : sortedComments
                ).map((comment, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{comment.book_name}</TableCell>
                    <TableCell>{comment.author_name}</TableCell>
                    <TableCell>{comment.user_name}</TableCell>
                    <TableCell>{new Date(comment.created_at).toLocaleDateString()}</TableCell>
                    <TableCell>
                      <Typography
                        onClick={
                          comment.status === "rejected"
                            ? () => handleDialogOpen(comment)
                            : null
                        }
                        sx={{
                          color: getStatusColor(comment.status),
                          cursor: comment.status === "rejected" ? "pointer" : "default",
                        }}
                      >
                        {comment.status}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <IconButton disableRipple>
                        <VisibilityIcon onClick={() => handleViewBook(comment)} />
                        <DeleteIcon onClick={() => handleOpenDialog(comment)} />
                        <Checkbox
                          checked={selectedComments.includes(comment)}
                          onChange={() => handleSelectComment(comment)}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100, 500]}
            component="div"
            count={comments.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <Dialog open={dialogOpen} onClose={handleCloseDialog}>
          <DialogTitle>View Comments</DialogTitle>
          <DialogContent>{dialogContent}</DialogContent>
          <DialogActions sx={{ padding: "10px" }}>
            <Button color="primary" onClick={handleCloseDialog}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to delete this comment?"}
          </DialogTitle>
          {/* <DialogContent>
                          <dialogContent id="alert-dialog-description">
                            This action is irreversible. Once deleted, the
                            comment cannot be recovered.
                          </dialogContent>
                        </DialogContent> */}
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
            <Button
              onClick={confirmDelete}
              color="secondary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <SnackbarContent
            style={getSnackbarStyle(messageType)}
            message={snackbarMessage}
          />
        </Snackbar>
      </Box>
    </>
  );
};

export default CommentsControl;
