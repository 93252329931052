import React, { useEffect, useState } from "react";
import {
  TextField,
  Box,
  Typography,
  Button,
  Grid,
  TablePagination,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Divider,
  FormControl,
  InputLabel,
  Select,
  Snackbar,
  Alert,
  FormHelperText,
  TableSortLabel,
} from "@mui/material";
import {
  Visibility as VisibilityIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import axiosInstance from "../utils/AxiosInstance";
import SearchIcon from "@mui/icons-material/Search";
import MuiAlert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
import {CloudUpload as CloudUploadIcon, Delete as DeleteIcon} from '@mui/icons-material';

const BooksApproval = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [books, setBooks] = useState([]);
  const [allBooks, setAllBooks] = useState([]);
  const [categories, setCategories] = useState([]);
  const [levels, setLevels] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [selectedBook, setSelectedBook] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [rejectCommand, setRejectCommand] = useState("");
  const [openPdfDialog, setOpenPdfDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const location=useLocation()
  // const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  // const [openErrorDialog, setOpenErrorDialog] = useState(false);
  // const [dialogMessage, setDialogMessage] = useState("");
  // const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [errors, setErrors] = useState({});
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState({
    english: "",
    tamil: "",
  });
  const [openSubCategoryDialog, setOpenSubCategoryDialog] = useState(false);
  const [newSubCategoryName, setNewSubCategoryName] = useState({
    english: "",
    tamil: "",
  });
  

  const navigate = useNavigate();

  // const [dialogType, setDialogType] = useState("success");

  //console.log("Selected cat:", books);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedRowInformation = (rows, comparator) => {
    const stabilizedRows = rows.map((el, index) => [el, index]);
    stabilizedRows.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedRows.map((el) => el[0]);
  };
  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const fetchBooks = async () => {
    try {
      const response = await axiosInstance.get(
        "/admin/books/waiting-for-approval"
      );
      setBooks(response.data.books);
      setAllBooks(response.data.books);
    } catch (err) {
      //console.error("Error fetching books:", err);
      if (err.response.status === 401) {
        window.location.href = '/auth/login';
      }      
      setError("Error fetching books");
    } finally {
      setLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axiosInstance.get("/books/categories");
      setCategories(response.data.categories);
      //console.log("retrieved cateo", response.data.categories);

      setLevels(response.data.levels);
    } catch (error) {
      //console.error("Error fetching categories:", error);
    }
  };

  const handleCategoryDialogClose = () => {
    setOpenCategoryDialog(false);
    setNewCategoryName({ english: "", tamil: "" });
  };

  const handleSubCategoryDialogClose = () => {
    setOpenSubCategoryDialog(false);
    setNewSubCategoryName({ english: "", tamil: "" });
  };

  useEffect(() => {
    fetchBooks();
    fetchCategories();
  }, []);

  const fetchSubcategories = async () => {
    if (selectedCategory) {
      try {
        const response = await axiosInstance.get("/books/subcategories", {
          params: { category_id: selectedCategory },
        });
        setSubcategories(response.data.subcategories);
      } catch (error) {
        //console.error("Error fetching subcategories:", error);
      }
    } else {
      setSubcategories([]);
    }
  };
  
  useEffect(() => {
    fetchSubcategories();
  }, [selectedCategory]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  const handleUpdateBook = async () => {
    if (
      !selectedBook.title ||
      !selectedBook?.title_tamil ||
      !selectedBook.author_name ||
      !selectedBook?.author_name_tamil ||
      !selectedBook.level_id ||
      !selectedCategory ||
      !selectedBook.subcategory_id ||      
      // !selectedBook.youtube_link ||
      !selectedBook.description
    ) {
      alert("Please fill in all required fields before approving.");
      return;
    }

    if (selectedBook) {
      try {
        let PdfResponse = selectedBook.pdf_url;

        if (pdf) {
          const formData = new FormData();
          formData.append("book", pdf);
          formData.append("bookTitle", selectedBook.title);

          const PDFupload = await axiosInstance.post("/upload/book", formData);
          PdfResponse = PDFupload.data.fileName;
        }

        const response = await axiosInstance.put(
          `/create/books/update-details/${selectedBook.book_id}`,
          {
            title: selectedBook.title,
            title_tamil: selectedBook.title_tamil,
            author_name: selectedBook.author_name,
            author_name_tamil: selectedBook.author_name_tamil,            
            subcategory_id: selectedBook.subcategory_id,
            level_id: selectedBook.level_id,
            youtube_link: selectedBook.youtube_link,
            description: selectedBook.description,
            pdf_url: PdfResponse,
          }
        );

        if (response.status === 200) {
          const updatedBook = response.data;

          setBooks((prevBooks) =>
            prevBooks.map((book) =>
              book.book_id === updatedBook.book_id ? updatedBook : book
            )
          );
          fetchBooks(); 
          setIsEditing(false);    
          setPdf(null);
          fetchBooks();                
        } else {
          //console.error("Failed to update book");
        }
      } catch (error) {
        if (error.response.status === 401) {
          window.location.href = '/auth/login';
        }      
        //console.error("Error updating book:", error);
      }
    }
  };

  const handleUpdateAndApprove = async () => {
    if (
      !selectedBook.title ||
      !selectedBook?.title_tamil ||
      !selectedBook.author_name ||
      !selectedBook?.author_name_tamil ||
      !selectedBook.level_id ||
      !selectedCategory ||
      !selectedBook.subcategory_id ||      
      // !selectedBook.youtube_link ||
      !selectedBook.description
    ) {
      alert("Please fill in all required fields before approving.");
      return;
    }

    if (selectedBook) {
      try {
        let PdfResponse = selectedBook.pdf_url;

        if (pdf) {
          const formData = new FormData();
          formData.append("book", pdf);
          formData.append("bookTitle", selectedBook.title);

          const PDFupload = await axiosInstance.post("/upload/book", formData);
          PdfResponse = PDFupload.data.fileName;
        }

        const response = await axiosInstance.put(
          `/admin/books/update-and-approve/${selectedBook.book_id}`,
          {
            title: selectedBook.title,
            title_tamil: selectedBook.title_tamil,
            author_name: selectedBook.author_name,
            author_name_tamil: selectedBook.author_name_tamil,
            author_email: selectedBook.author_email,
            subcategory_id: selectedBook.subcategory_id,
            level_id: selectedBook.level_id,
            youtube_link: selectedBook.youtube_link,
            description: selectedBook.description,
            pdf_url: PdfResponse,
          }
        );

        if (response.status === 200) {
          const updatedBook = response.data;

          setBooks((prevBooks) =>
            prevBooks.map((book) =>
              book.book_id === updatedBook.book_id ? updatedBook : book
            )
          );
          setOpen(true);
          //console.log(response.data.message);
          setOpenDialog(false);
          setIsEditing(false);        
          fetchBooks();
          setSnackbarMessage("Approved Successfully");
          setOpenSnackbar(true)
        } else {
          //console.error("Failed to update book");
        }
      } catch (error) {
        if (error.response.status === 401) {
          window.location.href = '/auth/login';
        }      
        //console.error("Error updating book:", error);
      }
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setOpenRejectDialog(false);
    setOpenPdfDialog(false);
    // setOpenSuccessDialog(false);
    // setOpenErrorDialog(false);
  };

  const handleReject = async () => {
    if (selectedBook) {
      try {
        const response = await axiosInstance.put(
          `/admin/books/reject/${selectedBook.book_id}`,
          {
            rejection_reason: rejectCommand,
          }
        );

        if (response.status === 200) {
          const updatedBook = response.data;
          //console.log(response.data.message);
          setBooks((prevBooks) =>
            prevBooks.map((book) =>
              book.book_id === updatedBook.book_id ? updatedBook : book
            )
          );
          setOpenDialog(false);
          handleRejectClose();
          fetchBooks();
          setSnackbarMessage("Rejected Successfully");
          setSnackbarSeverity("error");
          setOpenSnackbar(true);
        } else {
          //console.error("Failed to reject book");
        }
      } catch (error) {
        if (error.response.status === 401) {
          window.location.href = '/auth/login';
        }      
        //console.error("Error rejecting book:", error);
      }
    }
  };

  const handlePdfUpload = (event) => {
    const file = event.target.files[0];
    //console.log("==-----==", file)
    if (file && file.type === "application/pdf") {
      setPdf(file);
      //  //console.log("==---file---==", file)
      setSelectedBook({ ...selectedBook, updatedBook: file });
      setErrors((prevErrors) => ({
        ...prevErrors,
        pdf: "", // Clear the error when a file is selected
      }));
    } else {
      //console.log("Please upload a PDF file.");
    }
  };

  const handlePdfDialogOpen = () => {
    setSelectedPdf(selectedBook.pdf_url);
    setOpenPdfDialog(true);
  };

  const handlePdfDialogClose = () => {
    setOpenPdfDialog(false);
    setSelectedPdf(null);
  };

  const handleCategorySubmit = async () => {
    try {
      const response = await axiosInstance.post("/books/addCategory", {
        english: newCategoryName.english,
        tamil: newCategoryName.tamil,
      });
      //console.log("-----response", response);

      if (response.data.success) {
        // Update the category list with the new category
        // setCategories([...categories, response.data.category]);
        await fetchCategories();
        handleCategoryDialogClose();
      }
    } catch (error) {
      if (error.response.status === 401) {
        window.location.href = '/auth/login';
      }      
      //console.error("Error adding category:", error);
    }
  };

  const handleSubCategorySubmit = async () => {
    try {
      const response = await axiosInstance.post("/books/addSubCategory", {
        english: newSubCategoryName.english,
        tamil: newSubCategoryName.tamil,
        category_id: selectedCategory,
      });
      //console.log("-----response-----", response);

      if (response.data.success) {
        // Update the category list with the new category
        // setCategories([...categories, response.data.category]);
        await fetchSubcategories();
        handleSubCategoryDialogClose();
      }
    } catch (error) {
      if (error.response.status === 401) {
        window.location.href = '/auth/login';
      }      
      //console.error("Error adding category:", error);
    }
  };

  const handleDialogOpen = (book) => {
    setSelectedBook(book);
    setSelectedCategory(book.category_id);
    setSelectedSubcategory(book.subcategory_id);
    setOpenDialog(true);
    setIsEditing(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    const filteredBooks = allBooks.filter(
      (book) =>
        book.title.toLowerCase().includes(event.target.value.toLowerCase()) ||
        book.author_name
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        book.category_name
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        book.subcategory_name
          .toLowerCase()
          .includes(event.target.value.toLowerCase()) ||
        book.level_name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    //console.log("fil", filteredBooks);

    setBooks(filteredBooks);
    setPage(0);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedBook((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCategoryChange = (e) => {
    const categoryId = e.target.value;

    if (categoryId === "addCategory") {
      // Open the Add Category dialog
      setOpenCategoryDialog(true);
    } else {
      // Handle category selection
      setSelectedCategory(categoryId);
      setSelectedBook({
        ...selectedBook,
        category_id: categoryId,
        subcategory_id: "",
      });
    }
  };

  const handleSubcategoryChange = (e) => {
    const subcategoryId = e.target.value;

    if (subcategoryId === "addSubcategory") {
      // Open the Add Subcategory dialog
      setOpenSubCategoryDialog(true);
    } else {
      // Handle subcategory selection
      setSelectedSubcategory(subcategoryId);
      setSelectedBook({ ...selectedBook, subcategory_id: subcategoryId });
    }    
  };

  const handleRejectOpen = () => {
    setOpenRejectDialog(true);
  };

  const handleRejectClose = () => {
    setOpenRejectDialog(false);
    setRejectCommand("");
  };

  const handleEdit = (book) => {
    //console.log("ggg", book);
    localStorage.setItem('preveditpath',location.pathname)  
    navigate("/create/book", { state: { book: book } });
  };

  const handlePdfRemove = () => {
    setPdf(null);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "rejected":
        return "red";
      case "rejected by admin":
        return "red";
      case "deleted":
        return "#e17400";
      case "approved":
        return "#d8d800";

      case "published":
        return "#034103";
      case "submitted":
        return "#05e605";
      case "waiting for approval":
        return "#d8d800";
      default:
        return "black";
    }
  };

  const handleCloseClick = () => {
    setOpen(false);
    setOpenDialog(false);
  };

  return (
    <Box className="App">
      <Box
        style={{ marginBottom: "20px", display: "flex", alignItems: "center" }}
      >
        <Typography variant="h4" sx={{ flexGrow: 1 }}>
          Books Approval
        </Typography>
        <TextField
          label="Search Users"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
        />
      </Box>

      <Paper>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#1976d2" }}>
                <TableCell sx={{ color: "#fff" }}>S.No</TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <TableSortLabel
                    active={orderBy === "title"}
                    direction={orderBy === "title" ? order : "asc"}
                    onClick={() => handleRequestSort("title")}
                    hideSortIcon={false}
                    sx={{
                      color: orderBy === "title" ? "#000" : "#fff",
                      "& .MuiTableSortLabel-icon": {
                        opacity: 1,                          
                      },           
                      "&.MuiTableSortLabel-root": {
                        color: "#fff",
                      },
                      "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                        color: "#000", 
                      },
                      "&:hover": {
                        color: "#f0f0f0", 
                        "& .MuiTableSortLabel-icon": {
                          color: "#f0f0f0", 
                        },
                      }           
                    }}
                  >
                    Book Name
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <TableSortLabel
                    active={orderBy === "author_name"}
                    direction={orderBy === "author_name" ? order : "asc"}
                    onClick={() => handleRequestSort("author_name")}
                    hideSortIcon={false}
                    sx={{
                      color: orderBy === "author_name" ? "#000" : "#fff",
                      "& .MuiTableSortLabel-icon": {
                        opacity: 1,                          
                      },           
                      "&.MuiTableSortLabel-root": {
                        color: "#fff",
                      },
                      "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                        color: "#000", 
                      },
                      "&:hover": {
                        color: "#f0f0f0", 
                        "& .MuiTableSortLabel-icon": {
                          color: "#f0f0f0", 
                        },
                      }           
                    }}
                  >
                    Author Name
                  </TableSortLabel>
                </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    <TableSortLabel
                        active={orderBy === "school_name"}
                        direction={orderBy === "school_name" ? order : "asc"}
                        onClick={() => handleRequestSort("school_name")}
                        hideSortIcon={false}
                        sx={{
                          color: orderBy === "school_name" ? "#000" : "#fff",
                          "& .MuiTableSortLabel-icon": {
                            opacity: 1,                          
                          },           
                          "&.MuiTableSortLabel-root": {
                            color: "#fff",
                          },
                          "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                            color: "#000", 
                          },
                          "&:hover": {
                            color: "#f0f0f0", 
                            "& .MuiTableSortLabel-icon": {
                              color: "#f0f0f0", 
                            },
                          }           
                        }}
                    >
                    School Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    <TableSortLabel
                        active={orderBy === "grade"}
                        direction={orderBy === "grade" ? order : "asc"}
                        onClick={() => handleRequestSort("grade")}
                        hideSortIcon={false}
                        sx={{
                          color: orderBy === "grade" ? "#000" : "#fff",
                          "& .MuiTableSortLabel-icon": {
                            opacity: 1,                          
                          },           
                          "&.MuiTableSortLabel-root": {
                            color: "#fff",
                          },
                          "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                            color: "#000", 
                          },
                          "&:hover": {
                            color: "#f0f0f0", 
                            "& .MuiTableSortLabel-icon": {
                              color: "#f0f0f0", 
                            },
                          }           
                        }}
                    >
                    Grade
                    </TableSortLabel>
                  </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                  <TableSortLabel
                    active={orderBy === "created_at"}
                    direction={orderBy === "created_at" ? order : "asc"}
                    onClick={() => handleRequestSort("created_at")}
                    hideSortIcon={false}
                    sx={{
                      color: orderBy === "created_at" ? "#000" : "#fff",
                      "& .MuiTableSortLabel-icon": {
                        opacity: 1,                          
                      },           
                      "&.MuiTableSortLabel-root": {
                        color: "#fff",
                      },
                      "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                        color: "#000", 
                      },
                      "&:hover": {
                        color: "#f0f0f0", 
                        "& .MuiTableSortLabel-icon": {
                          color: "#f0f0f0", 
                        },
                      }           
                    }}
                  >
                    Submitted Date
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                <TableSortLabel
                    active={orderBy === "level_name"}
                    direction={orderBy === "level_name" ? order : "asc"}
                    onClick={() => handleRequestSort("level_name")}
                    hideSortIcon={false}
                    sx={{
                      color: orderBy === "level_name" ? "#000" : "#fff",
                      "& .MuiTableSortLabel-icon": {
                        opacity: 1,                          
                      },           
                      "&.MuiTableSortLabel-root": {
                        color: "#fff",
                      },
                      "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                        color: "#000", 
                      },
                      "&:hover": {
                        color: "#f0f0f0", 
                        "& .MuiTableSortLabel-icon": {
                          color: "#f0f0f0", 
                        },
                      }           
                    }}
                  >
                  Level
                  </TableSortLabel>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                <TableSortLabel
                    active={orderBy === "category_name"}
                    direction={orderBy === "category_name" ? order : "asc"}
                    onClick={() => handleRequestSort("category_name")}
                    hideSortIcon={false}
                    sx={{
                      color: orderBy === "category_name" ? "#000" : "#fff",
                      "& .MuiTableSortLabel-icon": {
                        opacity: 1,                          
                      },           
                      "&.MuiTableSortLabel-root": {
                        color: "#fff",
                      },
                      "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                        color: "#000", 
                      },
                      "&:hover": {
                        color: "#f0f0f0", 
                        "& .MuiTableSortLabel-icon": {
                          color: "#f0f0f0", 
                        },
                      }           
                    }}
                  >
                Category
                </TableSortLabel>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                <TableSortLabel
                    active={orderBy === "approval_status"}
                    direction={orderBy === "approval_status" ? order : "asc"}
                    onClick={() => handleRequestSort("approval_status")}
                    hideSortIcon={false}
                    sx={{
                      color: orderBy === "approval_status" ? "#000" : "#fff",
                      "& .MuiTableSortLabel-icon": {
                        opacity: 1,                          
                      },           
                      "&.MuiTableSortLabel-root": {
                        color: "#fff",
                      },
                      "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                        color: "#000", 
                      },
                      "&:hover": {
                        color: "#f0f0f0", 
                        "& .MuiTableSortLabel-icon": {
                          color: "#f0f0f0", 
                        },
                      }           
                    }}
                  >
                  Status
                  </TableSortLabel>
                  </TableCell>
                <TableCell sx={{ color: "#fff" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedRowInformation(
                books.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ),
                getComparator(order, orderBy)
              ).map((book, index) => (
                <TableRow key={book.book_id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{book.title}</TableCell>
                  <TableCell>{book.author_name}</TableCell>
                  <TableCell>{book.school_name}</TableCell>
                  <TableCell>{book.grade}</TableCell>
                  <TableCell>{new Date(book.created_at).toLocaleDateString()}</TableCell>
                  <TableCell>{book.level_name}</TableCell>
                  <TableCell>{book.category_name}</TableCell>
                  <TableCell>
                    <Typography
                      onClick={
                        book.approval_status === "rejected"
                          ? () => handleDialogOpen(book)
                          : null
                      }
                      sx={{
                        color: getStatusColor(book.approval_status),
                        cursor:
                          book.approval_status === "rejected"
                            ? "pointer"
                            : "default",
                      }}
                    >
                      {book.approval_status}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDialogOpen(book)}>
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100, 500]}
          component="div"
          count={books.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <Dialog open={openDialog} onClose={handleDialogClose} fullWidth>
          <DialogTitle>
            Book Details
            <IconButton
              sx={{ position: "absolute", right: 16, top: 16 }}
              onClick={handleCloseClick}
            >
              <CloseIcon />
            </IconButton>
            <IconButton
              sx={{ position: "absolute", right: 60, top: 16 }}
              onClick={handleEditClick}
            >
              <EditIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2} >
              {/* First Row */}
              <Grid item xs={12} container spacing={2} sx={{ mt: "10px" }}>
                <Grid item xs={6}>
                  <TextField
                    required
                    fullWidth
                    label="Book Name (English)"
                    value={selectedBook?.title || ""}
                    onChange={(e) =>
                      setSelectedBook({
                        ...selectedBook,
                        title: e.target.value,
                      })
                    }
                    variant="outlined"
                    disabled={!isEditing}
                    error={!!errors.title}
                    helperText={errors.title}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    fullWidth
                    label="Book Name (Tamil)"
                    value={selectedBook?.title_tamil || ""}
                    onChange={(e) =>
                      setSelectedBook({
                        ...selectedBook,
                        title_tamil: e.target.value,
                      })
                    }
                    variant="outlined"
                    disabled={!isEditing}
                    error={!!errors.title}
                    helperText={errors.title}
                  />
                </Grid>
              </Grid>
              {/* Tamil  */}
              <Grid item xs={12} container spacing={2} sx={{ mt: "10px" }}>
                <Grid item xs={6}>
                  <TextField
                    required
                    fullWidth
                    label="Author Name (English)"
                    value={selectedBook?.author_name  || ""}
                    onChange={(e) =>
                      setSelectedBook({
                        ...selectedBook,
                        author_name: e.target.value,
                      })
                    }
                    variant="outlined"
                    disabled={!isEditing}
                    error={!!errors.author_name}
                    helperText={errors.author_name}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    fullWidth
                    label="Author Name (Tamil)"
                    value={selectedBook?.author_name_tamil || ""}
                    onChange={(e) =>
                      setSelectedBook({
                        ...selectedBook,
                        author_name_tamil: e.target.value,
                      })
                    }
                    variant="outlined"
                    disabled={!isEditing}
                    error={!!errors.author_name}
                    helperText={errors.author_name}
                  />
                </Grid>
              </Grid>
              {/* Second Row */}
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={6}>
                  <FormControl variant="outlined" fullWidth margin="normal">
                    <InputLabel>Levels</InputLabel>
                    <Select
                      disabled={!isEditing}
                      value={selectedBook?.level_id || ""}
                      onChange={(e) =>
                        setSelectedBook({
                          ...selectedBook,
                          level_id: e.target.value,
                        })
                      }
                      label="Level"
                      required
                      sx={{
                        "&.MuiSelect-outlined": { color: "#000000" },
                        "& .MuiInputLabel-root": {
                          color: "#000000",
                        },
                      }}
                    >
                      <MenuItem value="">None</MenuItem>
                      {levels.map((level) => (
                        <MenuItem key={level.level_id} value={level.level_id}>
                          {level.level_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="outlined" fullWidth margin="normal">
                    <InputLabel>Category</InputLabel>
                    <Select
                      disabled={!isEditing}
                      value={selectedCategory || ""}
                      onChange={(e) => handleCategoryChange(e)}
                      label="Category"
                      required
                      sx={{
                        "&.MuiSelect-outlined": { color: "#000000" },
                        "& .MuiInputLabel-root": { color: "#000000" },
                      }}
                    >
                      <MenuItem value="">None</MenuItem>
                      {categories
                        ?.filter(
                          (category) =>
                            category &&
                            category.category_id &&
                            category.category_name
                        )
                        .map((category) => (
                          <MenuItem
                            key={category.category_id}
                            value={category.category_id}
                          >
                            {category.category_name}
                          </MenuItem>
                        ))}
                      <MenuItem value="addCategory">
                        + Add New Category
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl variant="outlined" fullWidth margin="normal">
                    <InputLabel>Subcategory</InputLabel>
                    <Select
                      disabled={!isEditing || !selectedCategory}
                      value={selectedSubcategory || ""}
                      onChange={(e) => {
                        handleSubcategoryChange(e);
                      }}
                      label="Subcategory"
                      required
                      sx={{
                        "&.MuiSelect-outlined": { color: "#000000" },
                        "& .MuiInputLabel-root": { color: "#000000" },
                      }}
                    >
                      <MenuItem value="">None</MenuItem>
                      {subcategories.map((subcategory) => (
                        <MenuItem
                          key={subcategory.subcategory_id}
                          value={subcategory.subcategory_id}
                        >
                          {subcategory.subcategory_name}
                        </MenuItem>
                      ))}
                      <MenuItem value="addSubcategory">
                        + Add New Subcategory
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl variant="outlined" fullWidth margin="normal">
                    <TextField
                      fullWidth
                      // required
                      label="Youtube Link"
                      value={selectedBook?.youtube_link || ""}
                      onChange={(e) =>
                        setSelectedBook({
                          ...selectedBook,
                          youtube_link: e.target.value,
                        })
                      }
                      variant="outlined"
                      disabled={!isEditing}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    label="Description"
                    value={selectedBook?.description || ""}
                    onChange={(e) =>
                      setSelectedBook({
                        ...selectedBook,
                        description: e.target.value,
                      })
                    }
                    variant="outlined"
                    multiline
                    rows={4}
                    disabled={!isEditing}
                  />
                </Grid>

                <Grid item xs={12} sx={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                  <input
                    type="file"
                    accept=".pdf"
                    onChange={handlePdfUpload}
                    style={{ display: "none" }}
                    id="upload-file"
                    disabled={!isEditing}
                  />

                 <label htmlFor="upload-file">
                  <IconButton 
                    disableRipple
                    component="span"      
                    disabled={!isEditing}
                    sx={{ color: '#1976D2', fontSize: 40, cursor: 'pointer' }}
                  >
                    <CloudUploadIcon fontSize="inherit" />
                    <Typography pl={1}>Update pdf</Typography>
                  </IconButton>                  
                </label>

                  {pdf && (
                    <Box>
                      <Typography variant="body2">
                        <IconButton disableRipple onClick={handlePdfRemove}>
                          <DeleteIcon sx={{ color: 'red' }} />
                        </IconButton>
                        {pdf.name}
                      </Typography>                             
                    </Box>
                  )}
                </Grid>

                <Grid item xs={12}>
                    <Button
                      disabled={!isEditing}
                      onClick={handleUpdateBook}
                      variant="outlined"
                      component="span"
                      fullWidth                    
                    >
                      Save Changes
                    </Button>
                </Grid>
                
              </Grid>

            </Grid>
             
          </DialogContent>
          <DialogActions>
              <Grid
                item
                xs={12}
                 mt={2}
                 pb={1}
                container
                spacing={1}
                justifyContent="space-around"
                
                
                
               >
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleEdit(selectedBook)}
                  >
                    Edit Book
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handlePdfDialogOpen}
                  >
                    View Book
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handleUpdateAndApprove}
                  >
                    Approve
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleRejectOpen}
                  >
                    Reject
                  </Button>
                </Grid>
              </Grid>
          </DialogActions>
         
        </Dialog>


        <Dialog
          open={openCategoryDialog}
          onClose={handleCategoryDialogClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Add New Category</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ marginTop: "10px" }}>
                <TextField
                  label="Category Name (English)"
                  fullWidth
                  value={newCategoryName.english}
                  onChange={(e) =>
                    setNewCategoryName({
                      ...newCategoryName,
                      english: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "10px" }}>
                <TextField
                  label="Category Name (Tamil)"
                  fullWidth
                  value={newCategoryName.tamil}
                  onChange={(e) =>
                    setNewCategoryName({
                      ...newCategoryName,
                      tamil: e.target.value,
                    })
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCategoryDialogClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleCategorySubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        {/* Add Category Dialog */}
        <Dialog
          open={openSubCategoryDialog}
          onClose={handleSubCategoryDialogClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Add New Subcategory</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ marginTop: "10px" }}>
                <TextField
                  label="Subcategory Name (English)"
                  fullWidth
                  value={newSubCategoryName.english}
                  onChange={(e) =>
                    setNewSubCategoryName({
                      ...newSubCategoryName,
                      english: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "10px" }}>
                <TextField
                  label="Subcategory Name (Tamil)"
                  fullWidth
                  value={newSubCategoryName.tamil}
                  onChange={(e) =>
                    setNewSubCategoryName({
                      ...newSubCategoryName,
                      tamil: e.target.value,
                    })
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubCategoryDialogClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleSubCategorySubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openRejectDialog} onClose={handleRejectClose} fullWidth>
          <DialogTitle>Reject Comment</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              // label="Reason for rejection"
              variant="outlined"
              value={rejectCommand}
              onChange={(e) => setRejectCommand(e.target.value)}
              multiline
              rows={4}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleReject} color="error">
              Reject
            </Button>
            <Button onClick={handleRejectClose}>Cancel</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={openPdfDialog}
          onClose={handlePdfDialogClose}
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle>View PDF</DialogTitle>
          <DialogContent>
            {selectedPdf && (
              <iframe
                src={`https://story-buzz.blr1.digitaloceanspaces.com/uploads/books/pdf/${selectedPdf}`}
                width="100%"
                height="600px"
                frameBorder="0"
                title="Book PDF"
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handlePdfDialogClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
      {/* <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right',  }} 
        sx={{top:'120px'}}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openerrorSnackbar}
        autoHideDuration={3000}
        onClose={handleerrorSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right',  }} 
        sx={{top:'120px'}}
      >
        <Alert
          onClose={handleerrorSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackbarerrorMessage}
        </Alert>
      </Snackbar> */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarSeverity}
          elevation={6}
          variant="filled"
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default BooksApproval;
