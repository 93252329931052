import React, { useEffect, useState } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, TextField, IconButton, TablePagination, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button,
  Typography,Box,
  TableSortLabel
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import BlockIcon from '@mui/icons-material/Block';
import axiosInstance from '../utils/AxiosInstance';

const UserTable = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedUser, setSelectedUser] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  
  

  const fetchRegisteredUsers = async () => {
    try {
      const response = await axiosInstance.get('/admin/registered-users');
      setUsers(response.data);
      setAllUsers(response.data)
      //console.log(response.data);
      
    } catch (err) {
      //console.error('Error fetching users:', err);
      if (err.response.status === 401) {
        window.location.href = '/auth/login';
      }      
      setError('Error fetching users');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {    
    fetchRegisteredUsers();
  }, []);
 
  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  const handleOpenDeleteUser=(id)=>{
    setSelectedUser(id)
    setOpenDeleteDialog(true)
  }
  const handleCloseDeleteDialog=()=>{
    setOpenDeleteDialog(false)
    
  }

  const handleDeleteUser = async (id) => {
    //console.log(id);
   
    
      try {
        const response = await axiosInstance.delete(`/admin/delete-user/${id}`);

        if (response.status === 200) {
          // alert(response.data.message);
          fetchRegisteredUsers();
        } else {
          alert('Failed to delete the user');
        }
      } catch (error) {
        //console.error("Error deleting user:", error);
        if (error.response.status === 401) {
          window.location.href = '/auth/login';
        }             
        else if (error.response && error.response.data && error.response.data.message) {
          alert(error.response.data.message);
        }
        else {
          alert("Server error while deleting the user");
        }
      }finally{
        setOpenDeleteDialog(false)
      }
    
  };


  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    const filteredUsers = allUsers.filter(user =>
      user.first_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
      user.last_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
      user.email.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setUsers(filteredUsers);
    setPage(0); 
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleBlockUser = (user) => {
    const updatedUsers = users.map(u => 
      u.email === user.email ? { ...u, blocked: true } : u
    );
    setUsers(updatedUsers);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage);


  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedRowInformation = (rows, comparator) => {
    const stabilizedRows = rows.map((el, index) => [el, index]);
    stabilizedRows.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedRows.map((el) => el[0]);
  };
  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };
  
  

  return (
    <Box>
        <Box style={{ marginBottom:'20px', display: 'flex', alignItems: 'center' }}>
      <Typography variant='h3' sx={{flexGrow:1}}>Users</Typography>
          <TextField
            label="Search Users"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearch}
           
            />
          
        </Box>
            <Paper>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow sx={{backgroundColor:'#1976d2'}}>
                <TableCell sx={{color:'#fff'}}>S.No</TableCell>
                <TableCell sx={{color:'#fff'}}>
                    <TableSortLabel
                        active={orderBy === "first_name"}
                        direction={orderBy === "first_name" ? order : "asc"}
                        onClick={() => handleRequestSort("first_name")}
                        hideSortIcon={false}
                        sx={{
                          color: orderBy === "first_name" ? "#000" : "#fff",
                          "& .MuiTableSortLabel-icon": {
                            opacity: 1,                          
                          },           
                          "&.MuiTableSortLabel-root": {
                            color: "#fff",
                          },
                          "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                            color: "#000", 
                          },
                          "&:hover": {
                            color: "#f0f0f0", 
                            "& .MuiTableSortLabel-icon": {
                              color: "#f0f0f0", 
                            },
                          }           
                        }}
                    >
                      First Name
                    </TableSortLabel>
                  </TableCell>
                <TableCell sx={{color:'#fff'}}>
                <TableSortLabel
                        active={orderBy === "last_name"}
                        direction={orderBy === "last_name" ? order : "asc"}
                        onClick={() => handleRequestSort("last_name")}
                        hideSortIcon={false}
                        sx={{
                          color: orderBy === "last_name" ? "#000" : "#fff",
                          "& .MuiTableSortLabel-icon": {
                            opacity: 1,                          
                          },           
                          "&.MuiTableSortLabel-root": {
                            color: "#fff",
                          },
                          "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                            color: "#000", 
                          },
                          "&:hover": {
                            color: "#f0f0f0", 
                            "& .MuiTableSortLabel-icon": {
                              color: "#f0f0f0", 
                            },
                          }           
                        }}
                    >
                      Last Name
                    </TableSortLabel>
                    </TableCell>
                <TableCell sx={{color:'#fff'}}>
                    <TableSortLabel
                        active={orderBy === "email"}
                        direction={orderBy === "email" ? order : "asc"}
                        onClick={() => handleRequestSort("email")}
                        hideSortIcon={false}
                        sx={{
                          color: orderBy === "email" ? "#000" : "#fff",
                          "& .MuiTableSortLabel-icon": {
                            opacity: 1,                          
                          },           
                          "&.MuiTableSortLabel-root": {
                            color: "#fff",
                          },
                          "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                            color: "#000", 
                          },
                          "&:hover": {
                            color: "#f0f0f0", 
                            "& .MuiTableSortLabel-icon": {
                              color: "#f0f0f0", 
                            },
                          }           
                        }}
                    >
                  Email
                  </TableSortLabel>
                  </TableCell>
                <TableCell sx={{color:'#fff'}}>
                <TableSortLabel
                        active={orderBy === "published_books_count"}
                        direction={orderBy === "published_books_count" ? order : "asc"}
                        onClick={() => handleRequestSort("published_books_count")}
                        hideSortIcon={false}
                        sx={{
                          color: orderBy === "published_books_count" ? "#000" : "#fff",
                          "& .MuiTableSortLabel-icon": {
                            opacity: 1,                          
                          },           
                          "&.MuiTableSortLabel-root": {
                            color: "#fff",
                          },
                          "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                            color: "#000", 
                          },
                          "&:hover": {
                            color: "#f0f0f0", 
                            "& .MuiTableSortLabel-icon": {
                              color: "#f0f0f0", 
                            },
                          }           
                        }}
                    >
                  Books Published
                  </TableSortLabel>
                  </TableCell>
                <TableCell sx={{color:'#fff'}}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {sortedRowInformation(
                users.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ),
                getComparator(order, orderBy)
              ).map((user, index) => (
                <TableRow key={user.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{user.first_name}</TableCell>
                  <TableCell>{user.last_name}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.published_books_count}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleOpenDeleteUser(user.id)}>
                      <DeleteIcon />
                    </IconButton>
                    {/* <IconButton onClick={() => handleBlockUser(user)}>
                      <BlockIcon />
                    </IconButton> */}
                  </TableCell>
                </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={5} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog
           open={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Are you sure you want to delete this User?"}
          </DialogTitle>
          <DialogActions>
            <Button color="primary" 
            onClick={handleCloseDeleteDialog}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleDeleteUser(selectedUser)}
              color="secondary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100, 500]}
          component="div"
          count={users.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {/* {selectedUser && (
          <Dialog
            open={dialogOpen}
            onClose={handleCloseDialog}
          >
            <DialogTitle>User Details</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <strong>First Name:</strong> {selectedUser.firstName} <br />
                <strong>Last Name:</strong> {selectedUser.lastName} <br />
                <strong>Email:</strong> {selectedUser.email} <br />
                <strong>Books Published:</strong> {selectedUser.booksPublished.join(', ')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                Close
              </Button>
              <Button 
                onClick={() => handleBlockUser(selectedUser)} 
                color="error" 
                style={{ backgroundColor: 'lightgreen' }}
              >
                Approve
              </Button>
            </DialogActions>
          </Dialog>
        )} */}
      </Paper>
    </Box>
  );
};

export default UserTable;
