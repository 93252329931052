import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardMedia, Typography, IconButton, Box, Grid, Tooltip } from '@mui/material';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faBook,faBookOpenReader } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from '../utils/AxiosInstance';
import LikeButton from './LikeButton';
import Favorite from '@mui/icons-material/Favorite';
import { Padding } from '@mui/icons-material';

function BookCard({ book, onBookClick, userId, setBooks }) {

    const formatCount = (count) => {
        if (count >= 1000) {
          return `${Math.floor(count / 1000)}k`;
        }
        return count.toString();
      };
    
    return (
        <Box component={Link}
            to={`/book/${book.book_id}`} 
            style={{ textDecoration: 'none' }}
            sx={{ position: 'static', '&:hover .overlay': { opacity: 1, transform: 'translateY(-55px)' }, m:"0px", p:"0px" }}
        >
            <Card
                key={book.book_id}
                sx={{
                    borderRadius: 5,
                    height: "260px",
                    width: "200px",                
                    overflow: 'hidden',
                    background: "linear-gradient(#FAEDD5, #D7B046)",
                    position: "relative",
                    zIndex: '1',
                    cursor:"pointer"
                }}                
            >
                <CardMedia
                    component="img"
                    height="200px"
                    width="180px"
                    image={`${process.env.REACT_APP_BOOK_COVER_BASE_URL}${book.cover_image_url}`}
                    title="Book Image"
                    sx={{
                        clipPath: 'path("M -20 220 C 37 120 173 223 227 163 L 1194 -334 L -1086 -500 Z")',
                        boxShadow: '0px 4px 20px 0px #00000040'
                    }}
                />
                <CardContent sx={{ width: "200px", p: "0px" }}>
                <Tooltip title={book.title}  placement="bottom-start">
                    <Typography
                        //gutterBottom
                        variant="body1"
                        //component="div"
                        sx={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            paddingLeft:'10px',
                            width: "100%",
                            color: '#eff5f6',
                            fontFamily: 'tamilfont',
                            textShadow: '1px 1px 0 #000, -1px -1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: '15px',
                            lineHeight: '1.2',
                            // display: '-webkit-box',
                            // WebkitLineClamp: 2,
                            // WebkitBoxOrient: 'vertical',
                        }}
                    >
                       {book.title}
                    </Typography>
                    </Tooltip> 
                    <Tooltip title={book.author_name}  placement="bottom-end">
                    <Typography
                        variant="body2"
                        sx={{
                            textAlign: 'center',
                            paddingTop:'10px',
                            paddingLeft:'10px',
                            paddingRight:'10px',
                            width: "200px",
                            marginRight:'10px',
                            color: 'black',
                            fontFamily: 'Roboto, sans-serif',
                            //textShadow: '1px 1px 0 #000, -1px -1px 0 #000, -1px 1px 0 #000, 1px -1px 0 #000',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: '13px',
                            lineHeight: '1.2',
                            fontWeight:"bold",
                        }}
                    >
                         -{book.author_name}
                    </Typography>
                    </Tooltip> 
                </CardContent>
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                        color: '#E4FAFE',
                        backgroundColor: 'transparent',
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                        '&:focus': {
                            outline: 'none',
                        }
                    }}
                >
                    {/* <BookmarkIcon sx={{ fontSize: 30 }} /> */}
                </IconButton>
            </Card>
            <Box
                className="overlay"
                sx={{
                    position: 'static',
                    bottom: 0,
                    left: 0,
                    width: '200px',
                    height: '100px',
                    background: "linear-gradient(#FAEDD5, #D7B046)",
                    borderRadius: '0 0 30px 30px',
                    opacity: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        width: '200px',
                        alignItems:"center",
                        pt: '55px',
                    }}
                >

                    {/* <LikeButton 
                        bookId={book.book_id}
                        userId={userId}
                        initialLikesCount={book.likes_count}
                        setBooks={setBooks}
                    /> */}
                    <Box display="flex" justifyContent="center" alignItems="center"> 
                         <FavoriteIcon sx={{ color:'#E4FAFE' }} fontSize="large"/> 
                         <Typography fontSize="25px" color="#E4FAFE" pl="2px">{formatCount(book.likes_count)}</Typography>  
                     </Box>

                     <Box disableRipple sx={{ color: '#E4FAFE'}} display="flex" justifyContent="center" alignItems="center">
                        <FontAwesomeIcon icon={faComment}  fontSize="27px"/>
                       <Typography fontSize="25px" color="#E4FAFE" pl="3px"> {formatCount(book.comments_count)}</Typography>
                    </Box>
                    <Box disableRipple sx={{ color: '#E4FAFE' }} display={'flex'} justifyContent='center' alignItems={'center'}>
                        <FontAwesomeIcon icon={faBookOpenReader} fontSize={'27px'} />
                       <Typography fontSize="25px" color="#E4FAFE" pl="3px" pt="2px">{formatCount(book.views_count)}</Typography>
                    </Box>

                    
                </Box>
            </Box>
        </Box>
    );
}

export default BookCard;
