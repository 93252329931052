import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogContent, DialogTitle, TextField, Box, Pagination, InputBase, Typography, TablePagination, DialogActions, Button, TableSortLabel } from '@mui/material';
import axiosInstance from '../utils/AxiosInstance';

const BookTable = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedBook, setSelectedBook] = useState(null);
  const [books, setBooks] = useState([]);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [allBooks, setAllBooks] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);

  //console.log(selectedBook, "kk");

  // const rowsPerPage = 10;

  //console.log("my", books);

  const handleDialogOpen = (book) => {
    setSelectedBook(book);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const fetchAllBooks = async () => {
      try {
        const response = await axiosInstance.get('/editor/books/books-with-status');
        const sortedBooks = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)); 
        setBooks(sortedBooks);
        setAllBooks(sortedBooks);
      } catch (error) {
        if (error.response.status === 401) {
          window.location.href = '/auth/login';
        }      
        //console.error('Error fetching published books:', error);
      }
    };
  
    fetchAllBooks();
  }, []);
  

  // Filter books based on search query
const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    const searchTermLower = event.target.value.toLowerCase();
    const filteredBooks = allBooks.filter(book =>{

      const title = book.title ? book.title.toLowerCase() : "";
      const author = book.author_name ? book.author_name.toLowerCase() : "";
      const category = book.category_name ? book.category_name.toLowerCase() : "";
      const subcategory = book.subcategory_name ? book.subcategory_name.toLowerCase() : "";
      const level = book.level_name ? book.level_name.toLowerCase() : "";
      return (
        title.includes(searchTermLower) ||
        author.includes(searchTermLower) ||
        category.includes(searchTermLower) ||
        subcategory.includes(searchTermLower) ||
        level.includes(searchTermLower)
      );
    }
    
     );    
    setBooks(filteredBooks);
    // setPage(0); 
  };
  
  const paginatedBooks = books.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);


  const getStatusColor = (status) => {
    switch (status) {
      case 'rejected':
        return 'red';
      case 'rejected by admin':
        return 'red';
      case 'deleted':
        return '#e17400';
      case 'approved':
        return 'green';
      case 'published':
        return 'green';
      case 'submitted':
        return '#d8d800';
      case 'waiting for approval':
        return '#d8d800';
      default:
        return 'black';
    }
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  
  const sortedRowInformation = (rows, comparator) => {
    const stabilizedRows = rows.map((el, index) => [el, index]);
    stabilizedRows.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedRows.map((el) => el[0]);
  };
  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };
  
  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  return (
    <Box>
      {/* Search Area */}
      <Box display={'flex'} sx={{ marginBottom: '20px' }}>
        <Typography variant='h4' sx={{ flexGrow: 1 }}>Book Status</Typography>
        <TextField
          placeholder="Search by book name "
          value={searchTerm}
          onChange={handleSearch}

        />
      </Box>

      {/* Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#1976d2" }}>
              <TableCell sx={{ color: '#fff' }}>S.No</TableCell>
              <TableCell sx={{ color: '#fff' }}>
              <TableSortLabel
                      active={orderBy === "title"}
                      direction={orderBy === "title" ? order : "asc"}
                      onClick={() => handleSort("title")}
                      hideSortIcon={false}
                      sx={{
                        color: orderBy === "title" ? "#000" : "#fff",
                        "& .MuiTableSortLabel-icon": {
                          opacity: 1,                          
                        },           
                        "&.MuiTableSortLabel-root": {
                          color: "#fff",
                        },
                        "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                          color: "#000", 
                        },
                        "&:hover": {
                          color: "#f0f0f0", 
                          "& .MuiTableSortLabel-icon": {
                            color: "#f0f0f0", 
                          },
                        }           
                      }}
                    >
                Book Name
                </TableSortLabel>
                </TableCell>
              <TableCell sx={{ color: '#fff' }}>
              <TableSortLabel
                      active={orderBy === "author_name"}
                      direction={orderBy === "author_name" ? order : "asc"}
                      onClick={() => handleSort("author_name")}
                      hideSortIcon={false}
                      sx={{
                        color: orderBy === "author_name" ? "#000" : "#fff",
                        "& .MuiTableSortLabel-icon": {
                          opacity: 1,                          
                        },           
                        "&.MuiTableSortLabel-root": {
                          color: "#fff",
                        },
                        "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                          color: "#000", 
                        },
                        "&:hover": {
                          color: "#f0f0f0", 
                          "& .MuiTableSortLabel-icon": {
                            color: "#f0f0f0", 
                          },
                        }           
                      }}
                    >
                      Author Name
                    </TableSortLabel>
                </TableCell>
                <TableCell sx={{ color: "#fff" }}>
                    <TableSortLabel
                        active={orderBy === "school_name"}
                        direction={orderBy === "school_name" ? order : "asc"}
                        onClick={() => handleSort("school_name")}
                        hideSortIcon={false}
                        sx={{
                          color: orderBy === "school_name" ? "#000" : "#fff",
                          "& .MuiTableSortLabel-icon": {
                            opacity: 1,                          
                          },           
                          "&.MuiTableSortLabel-root": {
                            color: "#fff",
                          },
                          "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                            color: "#000", 
                          },
                          "&:hover": {
                            color: "#f0f0f0", 
                            "& .MuiTableSortLabel-icon": {
                              color: "#f0f0f0", 
                            },
                          }           
                        }}
                    >
                    School Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    <TableSortLabel
                        active={orderBy === "grade"}
                        direction={orderBy === "grade" ? order : "asc"}
                        onClick={() => handleSort("grade")}
                        hideSortIcon={false}
                        sx={{
                          color: orderBy === "grade" ? "#000" : "#fff",
                          "& .MuiTableSortLabel-icon": {
                            opacity: 1,                          
                          },           
                          "&.MuiTableSortLabel-root": {
                            color: "#fff",
                          },
                          "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                            color: "#000", 
                          },
                          "&:hover": {
                            color: "#f0f0f0", 
                            "& .MuiTableSortLabel-icon": {
                              color: "#f0f0f0", 
                            },
                          }           
                        }}
                    >
                    Grade
                    </TableSortLabel>
                  </TableCell>
              <TableCell sx={{ color: '#fff' }}>
                  <TableSortLabel
                      active={orderBy === "created_at"}
                      direction={orderBy === "created_at" ? order : "asc"}
                      onClick={() => handleSort("created_at")}
                      hideSortIcon={false}
                      sx={{
                        color: orderBy === "created_at" ? "#000" : "#fff",
                        "& .MuiTableSortLabel-icon": {
                          opacity: 1,                          
                        },           
                        "&.MuiTableSortLabel-root": {
                          color: "#fff",
                        },
                        "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                          color: "#000", 
                        },
                        "&:hover": {
                          color: "#f0f0f0", 
                          "& .MuiTableSortLabel-icon": {
                            color: "#f0f0f0", 
                          },
                        }           
                      }}
                    >
                Submitted Date
                </TableSortLabel>
                </TableCell>
              <TableCell sx={{ color: '#fff' }}>
              <TableSortLabel
                      active={orderBy === "approval_status"}
                      direction={orderBy === "approval_status" ? order : "asc"}
                      onClick={() => handleSort("approval_status")}
                      hideSortIcon={false}
                      sx={{
                        color: orderBy === "approval_status" ? "#000" : "#fff",
                        "& .MuiTableSortLabel-icon": {
                          opacity: 1,                          
                        },           
                        "&.MuiTableSortLabel-root": {
                          color: "#fff",
                        },
                        "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                          color: "#000", 
                        },
                        "&:hover": {
                          color: "#f0f0f0", 
                          "& .MuiTableSortLabel-icon": {
                            color: "#f0f0f0", 
                          },
                        }           
                      }}
                    >
                Status
                </TableSortLabel>
                </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {sortedRowInformation(
                paginatedBooks.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ),
                getComparator(order, orderBy)
              ).map((book, index) => (
              <TableRow key={book.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{book.title}</TableCell>
                <TableCell>{book.author_name}</TableCell>
                <TableCell>{book.school_name}</TableCell>
                <TableCell>{book.grade}</TableCell>
                <TableCell>{new Date(book.created_at).toLocaleDateString()}</TableCell>
                {/* <TableCell>{book.created_at}</TableCell> */}
                <TableCell>
                  <Typography
                    onClick={book.approval_status === 'rejected' ? () => handleDialogOpen(book) : null}
                    sx={{ color: getStatusColor(book.approval_status), cursor: book.approval_status === 'rejected' ? 'pointer' : 'default' }}
                  >
                    {book.approval_status}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100, 500]}
        component="div"
        count={books.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Pagination */}
      {/* <Box mt={2} display="flex" justifyContent="center">
        <Pagination
          count={Math.ceil(books.length / rowsPerPage)}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box> */}

      {/* Rejection Reason Dialog */}
      <Dialog open={openDialog} onClose={handleDialogClose} fullWidth>
        <DialogTitle>Reason for Rejection</DialogTitle>
        <DialogContent>
          {selectedBook && selectedBook.rejection_reason ? (
            <TextField
              fullWidth
              // label="Reason"
              value={selectedBook.rejection_reason}
              variant="outlined"
              InputProps={{ readOnly: true }}
              multiline
              rows={4}
            />
          ) : (
            <Typography>No rejection reason available.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default BookTable;
