import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, TextField, IconButton, TablePagination, Button, Checkbox,
  Typography, Box,
  TableSortLabel
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import axiosInstance from '../utils/AxiosInstance';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from "@mui/material/Alert";
// import Alert from '@mui/material/Alert';
import DownloadIcon from '@mui/icons-material/Download';


const BooksPending = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [books, setBooks] = useState([]);
  const [allBooks, setAllBooks] = useState([]);
  const [selectedBooks, setSelectedBooks] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedBook, setSelectedBook] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [downloadPdfUrl, setDownloadPdfUrl] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);



  const fetchApprovedBooks = async () => {
    try {
      const response = await axiosInstance.get('/books/approved');
      setBooks(response.data);      
      
      setAllBooks(response.data);

    } catch (error) {
      if (error.response.status === 401) {
        window.location.href = '/auth/login';
      }      
      //console.error('Error fetching approved books:', error);
    }
  };

  useEffect(() => {
    fetchApprovedBooks();
  }, []);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    const filteredBooks = allBooks.filter(book =>
      book.title.toLowerCase().includes(event.target.value.toLowerCase()) ||
      book.author_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
      book.category_name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setBooks(filteredBooks);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectBook = (book) => {
    const updatedSelectedBooks = selectedBooks.includes(book)
      ? selectedBooks.filter(b => b !== book)
      : [...selectedBooks, book];
    setSelectedBooks(updatedSelectedBooks);
  };


  const handlePublishAll = async () => {
    const selectedBookIds = selectedBooks.map(book => book.book_id);

    try {
      await axiosInstance.put('/books/publish', { bookIds: selectedBookIds });

      const updatedBooks = books.map(book =>
        selectedBookIds.includes(book.book_id)
          ? { ...book, approval_status: 'published' }
          : book
      );

      setBooks(updatedBooks);
      setSelectedBooks([]);
      fetchApprovedBooks();

      // Set the Snackbar message and open it
      setSnackbarMessage("Published Successfully");
      setSnackbarSeverity("success");
      setOpenSnackbar(true);
    } catch (error) {
      if (error.response.status === 401) {
        window.location.href = '/auth/login';
      }      
      //console.error('Error publishing books:', error);
    }
  };


  const emptyRows = rowsPerPage - Math.min(rowsPerPage, books.length - page * rowsPerPage);

  const getStatusColor = (status) => {
    switch (status) {
      case 'rejected':
        return 'red';
      case 'rejected by admin':
        return 'red';
      case 'deleted':
        return '#e17400';
      case 'approved':
        return '#d8d800';

      case 'published':
        return '#034103';
      case 'submitted':
        return '#05e605';
      case 'waiting for approval':
        return '#d8d800';
      default:
        return 'black';
    }
  };
  const handleDialogOpen = (book) => {
    setSelectedBook(book);
    setOpenDialog(true);
  };


  const handleDownloadPdf = async (pdfUrl, title) => {    
    
    if (!pdfUrl) {
      //console.error("PDF URL is missing");
      return;
    }  
  
    try {      
      const response = await axiosInstance.post('/download/pdf', {pdfUrl, title});          
      const signedUrl = response.data.url;

      //console.log("sign", signedUrl);
      
  
      if (signedUrl) {
        const link = document.createElement('a');
        link.href = signedUrl;  
        link.setAttribute('download', `${title}.pdf`); 
        document.body.appendChild(link);
        link.click();  
        document.body.removeChild(link);
      }
    } catch (error) {
      //console.error('Error downloading the PDF:', error);
    }
  };


  const handleBulkDownloadPdf = async () => {
    if (selectedBooks.length === 0) return;
  
    try {
      for (const book of selectedBooks) {
        if (book.pdf_url) {
          const pdfUrl = `${process.env.REACT_APP_PDF_URL}${book.pdf_url}`;
          const response = await axiosInstance.post('/download/pdf', { pdfUrl, title: book.title });
          const signedUrl = response.data.url;
  
          if (signedUrl) {
            const link = document.createElement('a');
            link.href = signedUrl;
            link.setAttribute('download', `${book.title}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
              
            await new Promise((resolve) => setTimeout(resolve, 500));
          }
        }
      }
  
      setSelectedBooks([]);
  
    } catch (error) {
      //console.error('Error downloading the PDFs:', error);
      setSnackbarMessage("Error downloading PDFs.");
      setSnackbarSeverity("error");
      setOpenSnackbar(true);
    }
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedRowInformation = (rows, comparator) => {
    const stabilizedRows = rows.map((el, index) => [el, index]);
    stabilizedRows.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedRows.map((el) => el[0]);
  };
  const getComparator = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };
  
  
  
  
  
  

  return (
    <>
      <Box>

        <Box sx={{ marginBottom: '20px' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '15px' }}>
            <Typography variant="h4">Books Approved</Typography>
            <TextField
              label="Search Books"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearch}
              sx={{ marginLeft: '15px' }} // Optional: Add spacing between the title and the search box
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>

          
          <Button
            variant="contained"
            color="primary"
            startIcon={<DownloadIcon/>}
            onClick={handleBulkDownloadPdf}
            disabled={selectedBooks.length === 0}
            sx={{mr:"10px"}}
          >
            Download All
          </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={handlePublishAll}
              disabled={selectedBooks.length === 0}
            >
              Publish All
            </Button>
          </Box>

          {/* <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000} // Snackbar will auto-hide after 6 seconds
            onClose={() => setSnackbarOpen(false)}
          >
            <Alert onClose={() => setSnackbarOpen(false)} severity="success">
              {snackbarMessage}
            </Alert>
          </Snackbar> */}
        </Box>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity} elevation={6}
            variant="filled">
            {snackbarMessage}

          </MuiAlert>
        </Snackbar>

        <Paper>
          <TableContainer component={Paper}>
            <Table>
              <TableHead sx={{ backgroundColor: '#1976d2' }}>
                <TableRow>
                  <TableCell sx={{ color: "#fff" }}>S.No</TableCell>
                  <TableCell sx={{ color: '#fff' }}>
                    <TableSortLabel
                      active={orderBy === "title"}
                      direction={orderBy === "title" ? order : "asc"}
                      onClick={() => handleRequestSort("title")}
                      hideSortIcon={false}
                      sx={{
                        color: orderBy === "title" ? "#000" : "#fff",
                        "& .MuiTableSortLabel-icon": {
                          opacity: 1,                          
                        },           
                        "&.MuiTableSortLabel-root": {
                          color: "#fff",
                        },
                        "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                          color: "#000", 
                        },
                        "&:hover": {
                          color: "#f0f0f0", 
                          "& .MuiTableSortLabel-icon": {
                            color: "#f0f0f0", 
                          },
                        }           
                      }}
                    >
                    Book Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ color: '#fff' }}>
                    <TableSortLabel
                        active={orderBy === "author_name"}
                        direction={orderBy === "author_name" ? order : "asc"}
                        onClick={() => handleRequestSort("author_name")}
                        hideSortIcon={false}
                        sx={{
                          color: orderBy === "author_name" ? "#000" : "#fff",
                          "& .MuiTableSortLabel-icon": {
                            opacity: 1,                          
                          },           
                          "&.MuiTableSortLabel-root": {
                            color: "#fff",
                          },
                          "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                            color: "#000", 
                          },
                          "&:hover": {
                            color: "#f0f0f0", 
                            "& .MuiTableSortLabel-icon": {
                              color: "#f0f0f0", 
                            },
                          }           
                        }}
                      >
                        Author Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    <TableSortLabel
                        active={orderBy === "school_name"}
                        direction={orderBy === "school_name" ? order : "asc"}
                        onClick={() => handleRequestSort("school_name")}
                        hideSortIcon={false}
                        sx={{
                          color: orderBy === "school_name" ? "#000" : "#fff",
                          "& .MuiTableSortLabel-icon": {
                            opacity: 1,                          
                          },           
                          "&.MuiTableSortLabel-root": {
                            color: "#fff",
                          },
                          "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                            color: "#000", 
                          },
                          "&:hover": {
                            color: "#f0f0f0", 
                            "& .MuiTableSortLabel-icon": {
                              color: "#f0f0f0", 
                            },
                          }           
                        }}
                    >
                    School Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    <TableSortLabel
                        active={orderBy === "grade"}
                        direction={orderBy === "grade" ? order : "asc"}
                        onClick={() => handleRequestSort("grade")}
                        hideSortIcon={false}
                        sx={{
                          color: orderBy === "grade" ? "#000" : "#fff",
                          "& .MuiTableSortLabel-icon": {
                            opacity: 1,                          
                          },           
                          "&.MuiTableSortLabel-root": {
                            color: "#fff",
                          },
                          "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                            color: "#000", 
                          },
                          "&:hover": {
                            color: "#f0f0f0", 
                            "& .MuiTableSortLabel-icon": {
                              color: "#f0f0f0", 
                            },
                          }           
                        }}
                    >
                    Grade
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ color: "#fff" }}>
                    <TableSortLabel
                          active={orderBy === "level_name"}
                          direction={orderBy === "level_name" ? order : "asc"}
                          onClick={() => handleRequestSort("level_name")}
                          hideSortIcon={false}
                          sx={{
                            color: orderBy === "level_name" ? "#000" : "#fff",
                            "& .MuiTableSortLabel-icon": {
                              opacity: 1,                          
                            },           
                            "&.MuiTableSortLabel-root": {
                              color: "#fff",
                            },
                            "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                              color: "#000", 
                            },
                            "&:hover": {
                              color: "#f0f0f0", 
                              "& .MuiTableSortLabel-icon": {
                                color: "#f0f0f0", 
                              },
                            }           
                          }}
                        >
                        Level
                        </TableSortLabel>
                        </TableCell>
                  <TableCell sx={{ color: '#fff' }}>
                  <TableSortLabel
                        active={orderBy === "category_name"}
                        direction={orderBy === "category_name" ? order : "asc"}
                        onClick={() => handleRequestSort("category_name")}
                        hideSortIcon={false}
                        sx={{
                          color: orderBy === "category_name" ? "#000" : "#fff",
                          "& .MuiTableSortLabel-icon": {
                            opacity: 1,                          
                          },           
                          "&.MuiTableSortLabel-root": {
                            color: "#fff",
                          },
                          "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                            color: "#000", 
                          },
                          "&:hover": {
                            color: "#f0f0f0", 
                            "& .MuiTableSortLabel-icon": {
                              color: "#f0f0f0", 
                            },
                          }           
                        }}
                      >
                    Category
                    </TableSortLabel>
                    </TableCell>
                  <TableCell sx={{ color: '#fff' }}>                   
                    <TableSortLabel
                        active={orderBy === "approval_status"}
                        direction={orderBy === "approval_status" ? order : "asc"}
                        onClick={() => handleRequestSort("approval_status")}
                        hideSortIcon={false}
                        sx={{
                          color: orderBy === "approval_status" ? "#000" : "#fff",
                          "& .MuiTableSortLabel-icon": {
                            opacity: 1,                          
                          },           
                          "&.MuiTableSortLabel-root": {
                            color: "#fff",
                          },
                          "&.MuiTableSortLabel-root.MuiTableSortLabel-active": {
                            color: "#000", 
                          },
                          "&:hover": {
                            color: "#f0f0f0", 
                            "& .MuiTableSortLabel-icon": {
                              color: "#f0f0f0", 
                            },
                          }           
                        }}
                      >
                        Approval Status
                        </TableSortLabel>
                  </TableCell>
                  {/* New TableCell for PDF */}
                  <TableCell sx={{ color: '#fff' }}>PDF</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {sortedRowInformation(
                books.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ),
                getComparator(order, orderBy)
              ).map((book, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{book.title}</TableCell>
                    <TableCell>{book.author_name}</TableCell>
                    <TableCell>{book.school_name}</TableCell>
                    <TableCell>{book.grade}</TableCell>
                    <TableCell>{book.level_name}</TableCell>
                    <TableCell>{book.category_name}</TableCell>
                    <TableCell>
                      <Typography
                        onClick={book.approval_status === 'rejected' ? () => handleDialogOpen(book) : null}
                        sx={{ color: getStatusColor(book.approval_status), cursor: book.approval_status === 'rejected' ? 'pointer' : 'default' }}
                      >
                        {book.approval_status}
                      </Typography>
                    </TableCell>
                    {/* New TableCell for PDF Icon */}
                    <TableCell>
                      <IconButton onClick={() => handleDownloadPdf(`${process.env.REACT_APP_PDF_URL}${book.pdf_url}`, book.title)} disabled={!book.pdf_url} disableRipple>
                        <DownloadIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={selectedBooks.includes(book)}
                        onChange={() => handleSelectBook(book)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100, 500]}
            component="div"
            count={books.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  );
};

export default BooksPending;